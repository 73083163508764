<template>
  <div class="app-contianer">
    <div class="page-bg">
      <div class="bg"></div>
    </div>

    <div id="app-content">
      <div class="withdrawal">
        <div class="account-balance withdrawalBgUrl">
          <div class="f40">账户余额（元）</div>
          <div class="account-text">{{ account }}</div>
        </div>
        <div class="withdrawal-price">
          <div class="price-title">提现金额</div>
          <div class="price-input">
            <span class="icon">￥</span>
            <input type="text" v-model.number="amount" />
            <span class="f34 all" @click="setAll">全部</span>
          </div>
          <div class="submitBut" @click="gotophone">立即提现</div>
        </div>
        <div class="withdrawal-rule">
          <div class="item">提现规则:</div>
          <div class="item">1.请先确认提现账户是否正确</div>
          <!-- <div class="item">2.提现秒到账，无需手续费</div> -->
          <div class="item">2.提现金额不能低于1元</div>
          <div class="item">3.若提现失败，请联系人工客服妥善处理</div>
          <div class="item">(tip: 待入账余额将在第二天结算至余额钱包)</div>
        </div>
      </div>
      <div class="commission">
        <div class="commission-type">
          <div class="type-title" :class="type === 'trx' ? 'active' : ''" @click="selectType('trx')">余额提现</div>
          <div class="type-title" :class="type === 'liuqui' ? 'active' : ''" @click="selectType('liuqui')">佣金到账</div>
        </div>
        <div class="commission-time">
          <div class="date" @click="show = true">
            <van-icon name="notes-o"></van-icon>{{ date }}
          </div>
          <van-calendar v-model="show" :min-date="minDate" :max-date="maxDate" type="range" :allow-same-day="true"
            color="#6794ff" @confirm="onconfirm">
          </van-calendar>
        </div>
        <div class="balance-list" v-for="(item, index) in accountList" :key="index">
          <div class="balance-img">
            <div class="page-icon withdraw" v-if="type === 'trx'"></div>
            <div class="page-icon accounting" v-if="type === 'liuqui'"></div>
          </div>
          <div class="balance-text">
            <div class="balance-price2">{{type === 'trx'?'-':''}}{{item.amount}}元</div>
            <div class="balance-price">{{item.createTime}}</div>
            <div class="balance-price">明细：{{item.smstitle}}</div>
          </div>
          <div class="balance-last" v-if="type === 'trx'">
            <div class="balance-succes" v-if="item.status==='PAIED'">提现成功</div>
            <div class="balance-error" v-else>提现失败</div>
          </div>
          <div class="balance-last" v-if="type === 'liuqui'">
            <div class="balance-succes" v-if="item.status==='PAIED' || item.status==='FINISH'">入账成功</div>
            <div class="balance-error" v-else>入账失败</div>
          </div>
        </div>
        <van-divider dashed>{{loadtext}}</van-divider>
      </div>
    </div>

    <!-- 未绑定支付宝提示弹框 -->
    <van-popup v-model="showZfbTip" class="showZfbTip">
      <div class="content">
        <p>未绑定支付宝账号, 请先绑定支付宝账号</p>
      </div>
      <div class="footer">
        <span class="but" @click="showZfbTip = false">取消</span>
        <span class="but confirm" @click="bindZfb()">绑定支付宝</span>
      </div>
    </van-popup>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { getToken } from '../../../api'
import { getScroll, getScrolltop } from '../../../utils/utils'
import { Toast } from 'vant'
import thechangePhone from '@/components/changePhone.js'


export default {
  name: 'withdrawal',
  data() {
    return {
      account: '0.00',
      date: '选择日期',
      show: false,
      minDate: new Date(2021, 1, 1),
      maxDate: new Date(),
      amount: '',
      type: 'trx',
      beginTime: '',
      endTime: '',
      accountList: [],
      page: 1,
      pageSize: 50,
      flag: null,
      loadtext: '请稍后',
      payModel: 'WXPAY',
      showZfbTip: false,
      loading: false
    }
  },
  computed: {
    ...mapState('user', ['custMsg', 'versions']),
  },
  created() {
    const [start, end] = [this.getCurrentMonthFirst(), new Date()]
    this.show = false
    this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
    this.beginTime = `${this.formatDate(start)}`
    this.endTime = `${this.formatDate(end)}`

    this.getText()
    this.getList()
  },
  mounted() {
    window.addEventListener('scroll', this.scrollListener)
  },
  destroyed() {
    window.removeEventListener('scroll', this.scrollListener)
  },
  methods: {
    //余额
    getText() {
      getToken({ trxCode: 'TC902', requestType: 'applet' }).then((res) => {
        if (res.accArray && res.accArray.length > 0) {
          const accarray = res.accArray.find((acc) => acc.accCode === 'PROFIT')
          this.account = accarray.balance
        }
      })
    },
    //获取列表
    getList() {
      if (this.loading) return
      this.loading = true
      this.loadtext = '请稍后'
      getToken({
        trxCode: 'XF907',
        type: this.type,
        page: this.page,
        pageSize: this.pageSize,
        beginTime: this.beginTime,
        endTime: this.endTime,
      }).then((res) => {
        this.loading = false
        if (res.data && res.data.length > 0) {
          this.accountList = this.accountList.concat(res.data)
          if (res.data.length < 10) {
            this.loadtext = '没有更多了'
            this.flag = true
          } else {
            this.loadtext = '上拉加载'
            this.flag = false
          }
        } else {
          this.loadtext = '没有更多了'
        }
      })
    },

    // 切换type
    selectType(val) {
      if (this.loading) return
      if (this.type === val) {
        return
      }
      this.type = val
      this.accountList = []
      this.page = 1
      this.flag = false
      this.getList()
    },
    //选择日期后
    onconfirm(date) {
      const [start, end] = date
      this.show = false
      this.date = `${this.formatDate(start)} - ${this.formatDate(end)}`
      this.beginTime = `${this.formatDate(start)}`
      this.endTime = `${this.formatDate(end)}`
      this.accountList = []
      this.page = 1
      this.flag = false
      this.getList()
    },
    //日期格式
    formatDate(date) {
      return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
    },
    // 获取当前月份的第一天
    getCurrentMonthFirst() {
      var date = new Date()
      return new Date(date.getFullYear(), date.getMonth(), 1)
    },

    // 提现
    gotophone() {
      // 未绑定手机号
      if (this.custMsg.phoneUser === "N") {
        thechangePhone()
        return
      }
      // TODO 目前只支持支付宝提现
      this.payModel = 'ALIPAY'
      this.H5Common()
    },
    // 校验支付宝账号
    H5Common() {
      if (!this.custMsg.logonId || !this.custMsg.username) {
        this.showZfbTip = true
      } else {
        this.onSubmit()
      }
    },
    //提现数据处理
    onSubmit() {
      let getMoney = parseInt((this.account * 100) / 100)
      if (this.amount === 0 || this.amount === '') {
        this.$toast('提现金额不能为零')
        return
      }

      if (this.amount > getMoney) {
        this.$toast.fail('您的余额不足')
        return
      }

      if (this.custMsg.level > 1) {
        if (this.amount < 2) {
          this.$toast.fail('金额需大于或等于2元')
        } else {
          this.getsubmit()
        }
      } else {
        if (this.amount < 1) {
          this.$toast.fail('金额需大于或等于1元')
        } else {
          this.getsubmit()
        }
      }
    },
    // 提现
    getsubmit() {
      if (this.loading) return
      this.loading = true
      let toast = Toast.loading({
        duration: 0, // 持续展示
        forbidClick: true,
        message: '受理中...',
      })
      setTimeout(() => {
        toast.clear()
        this.getText()
      }, 3000)
      getToken({
        trxCode: 'TC023',
        amount: this.amount,
        type: 'MP',
        payModel: this.payModel,
      }).then((res) => {
        this.loading = false
        this.amount = ''
        toast.clear()
        if (res.rspMsg) {
          this.$toast(res.rspMsg)
        } else {
          this.$toast('已受理')
        }
      })
    },
    //点击全部后
    setAll() {
      var str = this.account.toString()
      var ad = parseInt(str.substring(0, str.indexOf('.')))
      this.amount = ad
    },
    // 滚动监听
    scrollListener() {
      //总高度=滚动条滚动的高度+cliebtHeight（要是想不到底部就加载数据可以写成）
      //offsetHeight +clientHeight>=scrollHeight-n (n是任意数字)
      var hegit = getScrolltop()
      var scroll = getScroll()
      if (hegit >= scroll) {
        if (this.flag === false) {
          this.flag = true
          this.page++
          this.getList()
        }
      }
    },
    bindZfb() {
      this.$router.push({ path: '/setLogon' })
    },
  },
}
</script>

<style lang="less" scoped>
.withdrawal {
  background: #ffffff;
  border-radius: 12px;
  margin: 12px;
  padding-bottom: 12px;

  .account-balance {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 16.2vh;
    // background: linear-gradient(-90deg, #997bee 0%, #709af2 100%);
    padding: 0 4vw;
    color: #ffffff;
    background-size: 100% 100%;

    .account-text {
      font-size: 7vw;
      font-weight: bold;
    }
  }

  .withdrawal-price {
    padding: 0 4vw;

    .price-title {
      font-size: 3.8vw;
      font-weight: bold;
      color: #333;
      margin: 4vw 0;
    }

    .price-input {
      width: 100%;
      display: flex;
      align-items: center;
      border-bottom: 1px solid #ccc;

      .icon {
        font-size: 9vw;
      }
      input {
        width: 70%;
        border: none;
        outline: none;
        font-size: 6vw;
        font-weight: 400;
      }
      .all {
        width: 15%;
        text-align: right;
      }
    }
  }

  .withdrawal-rule {
    padding: 0 4vw;
    font-size: 3.2vw;
    color: #999999;
    .item {
      margin-bottom: 1vw;
    }
  }
}

.commission {
  margin: 12px;
  background: #ffffff;
  border-radius: 12px;
  padding: 12px;
  box-sizing: border-box;

  .commission-type {
    display: flex;
    justify-content: space-around;

    .type-title {
      position: relative;
      text-align: center;
      font-size: 3.8vw;
      color: #666666;
    }
    .type-title.active {
      font-weight: bold;
      color: #222222;
    }
    .type-title.active::after {
      content: '';
      position: absolute;
      display: inline-block;
      width: 20px;
      margin-left: -10px;
      height: 4px;
      border-radius: 2px;
      bottom: -6px;
      left: 50%;
    }
  }

  .commission-time {
    margin-top: 4vw;
    .date {
      border: 1px solid #999999;
      border-radius: 6vw;
      font-size: 3.8vw;
      color: #999999;
      text-align: center;
      padding: 2vw 0;
    }
  }
}
.showZfbTip {
  width: 64%;
  border-radius: 2vw;

  .content {
    padding: 6vw 6vw 3vw;
  }
  .footer {
    .but {
      display: inline-block;
      width: 50%;
      text-align: center;
      padding: 4vw 0;
    }

    .but.confirm {
      color: #6794ff;
    }
  }
}
.balance-list {
  margin-top: 3vw;
  display: flex;
  align-items: center;

  .balance-img {
    .page-icon.withdraw {
      width: 54px;
      height: 54px;
      background-position: -451px -75px;
    }
    .page-icon.accounting {
      width: 54px;
      height: 54px;
      background-position: -517px -75px;
    }
    img {
      width: 6.7vh;
    }
  }

  .balance-text {
    flex: 1;
    margin: 0 3vw;
    font-size: 3.2vw;
    color: #999999;

    .balance-price,
    .balance-price2 {
      margin-bottom: 1.5vw;
    }

    .balance-price2 {
      font-size: 4vw;
      font-weight: bold;
      color: #333;
    }
  }

  .balance-last {
    .balance-succes {
      padding: 1vw 2vw;
      background: #e2ffd7;
      border: 1px solid #84da5b;
      border-radius: 4px;
      font-size: 3vw;
      color: #84db5c;
    }

    .balance-error {
      padding: 1vw 2vw;
      background: #e2ffd7;
      border-radius: 4px;
      font-size: 3vw;
      background: #ffe7e7;
      border: 1px solid #ff6c6c;
      color: #ff6c6c;
    }
  }
}
</style>
